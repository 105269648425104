<template>
  <div>
    <s-modal
      :visible.sync="visible"
      class="heartbeat"
      :title="$t('session_timeout')"
    >
      <div class="wrap">
        <div class="icon">
          <s-icon
            height="30"
            icon-name="warning"
            style="min-width: 30px"
            width="30"
          ></s-icon>
        </div>
        <div class="message">
          <div class="title">{{ $t('session_timeout') }}</div>
          <p>{{ $t('your_session_will_expire_in') }}</p>
          <h1>{{ countdownFormat(sessionDuration) }}</h1>
          <p>{{ $t('click_continue_or_logout') }}</p>
        </div>
      </div>
      <template slot="footer">
        <s-button button-type="outline-secondary" @click.native="doLogout">
          {{ $t('log_out') }}
        </s-button>
        <s-button
          button-type="primary"
          @click.native="doContinue"
          :loading="loading"
        >
          {{ $t('continue') }}
        </s-button>
      </template>
    </s-modal>
  </div>
</template>
<script>
import _ from 'lodash'
const LOGOUT_URL = '/auth/logout'
const HEARTBEAT_URL = '/auth/islogin'
const DO_NOT_NEED_HEART_BEAT_URL_LIST = [
  '/auth/login',
  '/auth/sms',
  '/terms-use',
  '/auth/forgot-password',
  '/log-out',
  '/auth/invalid-link',
  '/auth/confirm',
  '/link-expired',
  '/auth/sso-register',
  '/auth/sso-login',
  '/auth/check-access-code',
]

export default {
  data() {
    return {
      visible: false,
      expireAction: '',
      expireLocation: '',
      isLock: false, // quickly operation lock
      reserveDurationFrontend: 30 * 1000, // reserve duration for frontend local
      reserveDurationBackend: 5 * 1000, // reserve duration for backend server
      countdownDuration: 2 * 60 * 1000, // countdown duration frontend frontend local
      lastTime: Date.now(), // last time heart beat
      sessionDuration: 0, // session will be inactive after passed this duration
      coldDownDuration: 30 * 1000, // in 30s will be only a heart-beat call
      loading: false,
      sessionRecordTime: null,
      sessionDurationInitValue: 0,
    }
  },
  created() {
    if (this.doNotNeedHeartBeat()) {
      return false
    }
    this.sendHeartbeat()
    this.addListener()
  },
  mounted() {
    if (!this.doNotNeedHeartBeat()) {
      document.addEventListener('visibilitychange', () => {
        if (!document.hidden && !this.doNotContinueHeartBeat()) {
          if (this.sessionRecordTime && this.sessionDurationInitValue) {
            const restDuration =
              this.sessionDurationInitValue -
              (Date.now() - this.sessionRecordTime)
            this.sessionDuration = restDuration <= 0 ? 0 : restDuration
            if (restDuration <= 0) {
              this.sessionTimeOut()
            } else {
              this.addCountdown()
            }
          }

          this.loading = true
          this.heartbeatApi().then(() => {
            this.loading = false
          })
        }
      })
    }
  },
  methods: {
    heartbeatApi() {
      return this.$http
        .get(HEARTBEAT_URL, {
          params: this.$store.state.needSaveIpToServer
            ? { needRecordIp: '1' }
            : {},
        })
        .then(({ data }) => {
          return data
        })
        .catch((e) => {
          const errorResponse = e?.response?.data

          if (errorResponse && errorResponse.code === 110602) {
            this.doCleanTimerAndListeners()
            return { ignoreError: true }
          } else {
            throw e
          }
        })
        .finally(() => {
          this.$store.commit('setNeedSaveIpToServer', false)
        })
    },
    removeListener() {
      document.removeEventListener('mousedown', this.afterOperate)
      document.removeEventListener('scroll', this.afterOperate)
      document.removeEventListener('keypress', this.afterOperate)
    },
    /**
     * add listener for key press, mouse down, scroll event
     * */
    addListener() {
      document.addEventListener('mousedown', this.afterOperate)
      document.addEventListener('scroll', this.afterOperate)
      document.addEventListener('keypress', this.afterOperate)

      this.$once('hook:beforeDestroy', this.doCleanTimerAndListeners)
    },
    doCleanTimerAndListeners() {
      this.clearTimer()
      this.removeListener()
    },
    // if user already logout, stop heart beat.
    doNotContinueHeartBeat() {
      if (this.$store.state.logoutBecauseOfSingleDeviceRule) {
        this.doCleanTimerAndListeners()
        return true
      } else {
        return false
      }
    },
    afterOperate: _.debounce(function () {
      if (this._isDestroyed) {
        return
      }
      if (!this.isLock) {
        const currentTime = Date.now()

        if (currentTime <= this.lastTime + this.coldDownDuration) {
          this.logger(
            'user operated in heart beat cold down duration. so no heart beat.'
          )
        } else if (!this.doNotContinueHeartBeat()) {
          // need a new heart beat
          this.logger(
            'user operated in out range of cold down duration, so need a new heart beat.'
          )
          this.sendHeartbeat()
        }
      }
    }, 500),
    doNotNeedHeartBeat() {
      return (
        DO_NOT_NEED_HEART_BEAT_URL_LIST.indexOf(window.location.pathname) >
          -1 || window.location.pathname.indexOf('logout') >= 0
      )
    },
    addCountdown() {
      if (this.doNotContinueHeartBeat()) {
        return
      }
      this.logger('count down duration: ', this.sessionDuration)
      /**
       * How many time left to show countdown.
       * If time is big, decrease it in sessionDuration - countdownDuration - reserveDurationFrontend.
       * If time is small under countdownDuration + reserveDurationFrontend, we decrease it one second by one second.
       */
      const { sessionDuration, reserveDurationFrontend, countdownDuration } =
        this
      let countdownInterval

      if (sessionDuration <= countdownDuration + reserveDurationFrontend) {
        countdownInterval = 1000
      } else {
        countdownInterval =
          sessionDuration - countdownDuration - reserveDurationFrontend
      }

      this.clearTimer()
      this.countdownTimer = setTimeout(() => {
        this.sessionDuration -= countdownInterval

        if (!this.visible && this.sessionDuration <= this.countdownDuration) {
          this.logger('count down start!', this.sessionDuration)
          this.visible = true
          this.isLock = true
        }

        if (this.sessionDuration <= 0) {
          this.sessionTimeOut()
        } else {
          this.addCountdown()
        }
      }, countdownInterval)
    },
    sessionTimeOut() {
      this.visible = false
      this.clearTimer()
      this.logger('time out.')
      this.logout()
    },
    doLogout() {
      this.visible = false
      this.clearTimer()
      this.logger('log out')
      this.logout()
    },
    doContinue() {
      this.visible = false
      this.logger('continue heart-beat.')
      this.sendHeartbeat()
    },
    sendHeartbeat() {
      this.isLock = true
      this.clearTimer()
      this.logger('send a heart-beat!')

      this.heartbeatApi()
        .then((data) => {
          if (data && data.sessionDuration) {
            this.expireAction = data.expireAction
            this.expireLocation = data.expireLocation
            this.$utils.setSessionStorage(
              'EXPIRE_LOCATION',
              data.expireLocation
            )
            this.logger('expire action type: ', this.expireAction)
            this.sessionDuration =
              Number(data.sessionDuration) * 1000 - this.reserveDurationBackend
            this.sessionRecordTime = Date.now()
            this.sessionDurationInitValue =
              Number(data.sessionDuration) * 1000 - this.reserveDurationBackend
            this.logger(
              'heart-beat success! sessionDuration updated to ',
              this.sessionDuration
            )

            this.visible = false
            this.isLock = false
            this.lastTime = Date.now()
            this.addCountdown()
            this.logger('heart-beat ended!')
          } else if (!data?.ignoreError) {
            throw new Error('logout')
          }
        })
        .catch((e) => {
          console.error(e)
          this.clearTimer()
          this.logger('heart-beat failed!')
          window.location.href = LOGOUT_URL
        })
    },
    clearTimer() {
      this.countdownTimer && clearTimeout(this.countdownTimer)
    },
    logger() {
      if (this.$dev) {
        console.log(...arguments)
      }
    },
    logout() {
      const { $store: store } = this
      const { role } = store.getters

      this.$utils.clearSessionStorage()
      if (this.expireAction === 'redirect') {
        const logoutPageConfig =
          store.state.frontendConfig?.settings?.logoutPage || {}
        const logoutPage = logoutPageConfig[role]
        window.location.href = logoutPage || this.expireLocation || LOGOUT_URL
      } else {
        this.$router.push('/log-out')
      }
    },
    countdownFormat(time) {
      let minute = Math.floor(time / 1000 / 60)
      let second = Math.floor((time / 1000) % 60)
      minute = minute < 10 ? '0' + minute : minute
      second = second < 10 ? '0' + second : second
      return minute + ':' + second
    },
  },
}
</script>

<style lang="scss" scoped>
:deep() {
  &.heartbeat {
    z-index: 9999 !important;
  }
  .s-modal {
    margin-top: 30vh !important;
  }
  .s-handle-icon.s-icon {
    display: none !important;
  }
  .s-modal-header,
  .s-modal-footer {
    border: none !important;
  }
  .s-modal-header {
    padding-top: map-get($spacers, '8') !important;
    padding-bottom: map-get($spacers, '8') !important;
    .s-modal-title,
    button.s-btn {
      display: none;
    }
  }
}
.wrap {
  display: flex;
}
.icon {
  color: map-get($info-colors, 'warning');
  flex-grow: 0;
  padding-right: map-get($spacers, '8');
}
.message {
  flex-grow: 1;
}
.title {
  line-height: 30px;
  font-size: map-get($font-size, '18');
  font-weight: $font-weight-bold;
}
</style>

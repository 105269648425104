<template>
  <s-dropdown-item
    v-if="showSwitchAccount"
    id="nav_switch-account"
    class="log-out mt-8"
    @click="linkToLandingPage"
  >
    {{ $ts('switch_account') }}
  </s-dropdown-item>
</template>

<script>
const menuCodes = {
  myLine: 'MY_LINE',
  myApplication: 'MY_APPLICATION',
}

const lineStatus = {
  active: 'ACTIVE',
  pending: 'PENDING',
  pendingClose: 'PENDING_CLOSE',
  conditional: 'CONDITIONAL',
  frozen: 'FROZEN',
  closed: 'CLOSED',
}

const applicationStatus = {
  applicationInProgress: 'APPLICATION_IN_PROGRESS',
  applicationSubmitted: 'APPLICATION_SUBMITTED',
  underwriting: 'UNDERWRITING',
  revisionRequested: 'REVISION_REQUESTED',
  cancelled: 'CANCELLED',
  declined: 'DECLINED',
  locOpenRequested: 'LOC_OPEN_REQUESTED',
  completed: 'COMPLETED',
}

export default {
  name: 'switch-account',
  data() {
    return {
      myLinesCounts: 0,
      myApplicationCounts: 0,
    }
  },
  computed: {
    isCustomer() {
      const userType = {
        lender: 'LENDER',
        investment: 'INVESTMENT',
        customer: 'CUSTOMER',
      }
      return userType.customer === this.$store.state.userInfo?.profile?.roleType
    },
    menuList() {
      return this.$store?.state?.userInfo?.roleConfiguration?.menuList || []
    },
    menusIncludeMyLine() {
      return this.menuList.some((item) => item.menuCode === menuCodes.myLine)
    },
    menusIncludeMyApplication() {
      return this.menuList.some(
        (item) => item.menuCode === menuCodes.myApplication
      )
    },
    showSwitchAccount() {
      const isExistMultiLinePlusApplication =
        this.myLinesCounts + this.myApplicationCounts > 1
      return isExistMultiLinePlusApplication
    },
  },
  created() {
    this.setLineCountAndApplicationCount()
  },
  methods: {
    setLineCount() {
      if (this.menusIncludeMyLine) {
        this.$http.get('/v5/loan-package/my-lines').then(({ data }) => {
          data = data || []
          data = data.filter((item) => {
            const status = this.$lodash.toUpper(item?.status ?? '')
            return [lineStatus.active, lineStatus.pendingClose].includes(status)
          })
          this.myLinesCounts = data.length
        })
      }
    },
    setApplicationCount() {
      if (this.menusIncludeMyApplication) {
        this.$http.get('/v5/applications/mine').then(({ data }) => {
          data = data || []
          data = data.filter((item) => {
            return ![
              applicationStatus.cancelled,
              applicationStatus.declined,
              applicationStatus.completed,
            ].includes(item.status)
          })
          this.myApplicationCounts = data.length
        })
      }
    },
    setLineCountAndApplicationCount() {
      if (this.isCustomer) {
        this.setLineCount()
        this.setApplicationCount()
      }
    },
    linkToLandingPage() {
      this.$router.push({
        path: '/service/landing-page',
      })
    },
  },
}
</script>

import SystemError from './SystemError.vue'
import _ from 'lodash'
import utils from '../utils/index.js'
import store from '../store'

const userError = _.debounce((error) => {
  globalThis.microAppData.vue.prototype.$s_confirm({
    type: 'warning',
    title:
      error.message ||
      globalThis.microAppData.vue.prototype.$ts('unknown_error_tips'),
    message: `Request Id: ${error.requestId}`,
    placement: 'topRight',
    confirmText: globalThis.microAppData.vue.prototype.$ts('confirm'),
    cancelText: '',
  })
}, 1500)

const systemError = _.debounce((error) => {
  globalThis.microAppData.vue.prototype.$s_toast({
    type: 'error',
    message: (h) => h(SystemError, { props: error }),
    placement: 'topRight',
  })
}, 1500)

const deviceLogoutError = _.debounce((error) => {
  if (error.message) {
    globalThis.microAppData.vue.prototype.$s_confirm({
      type: 'warning',
      title: error.message,
      confirmText: globalThis.microAppData.vue.prototype.$ts('confirm'),
      cancelText: '',
      closeIcon: false,
      onOk() {
        store.commit('setLogoutBecauseOfSingleDeviceRule', false)
        // get logout page
        const { role } = store.getters
        const logoutPageConfig =
          store.state.frontendConfig?.settings?.logoutPage || {}
        const heatBeatLogOutPage = utils.getSessionStorage('EXPIRE_LOCATION')
        const logoutPage =
          logoutPageConfig[role] || heatBeatLogOutPage || '/auth/login'

        window.location.href = logoutPage
      },
    })
  }
}, 1500)

const navigationToLoginOrLogout = (error, toLogin) => {
  // eslint-disable-next-line
  return new Promise(function (_resolve, reject) {
    reject(error)
    if (!store.state.logoutBecauseOfSingleDeviceRule) {
      setTimeout(function () {
        const targetPath = toLogin ? '/auth/login' : '/auth/logout'
        const currentPath = window.location.pathname
        if (
          targetPath !== currentPath &&
          !store.state.logoutBecauseOfSingleDeviceRule
        ) {
          window.location.href = targetPath
        }
      }, 1000)
    }
  })
}

const ignoreError = (error) => {
  const resData = error.response?.data
  const ignoreErrorCodes = error.config?.ignoreErrorCodes || []
  return (
    ignoreErrorCodes.includes(resData?.code) || ignoreErrorCodes.includes('any')
  )
}

export default (error) => {
  if (!error || error?.code === 'ECONNABORTED') {
    return Promise.reject(error)
  }
  /* global globalThis */
  const router = globalThis.microAppData.router
  const resData = error.response?.data
  if (ignoreError(error)) {
    return Promise.reject(resData)
  }

  try {
    if (error?.response) {
      // time out, throws an exception after 30s
      error.code === 'ECONNABORTED' &&
        systemError({ message: 'Request timeout, Please try again later' })

      // Special error code handling
      if (resData) {
        // pop-up window for form validate errors
        // if (resData.code && resData.code.toString().indexOf('1044') > -1) {}
        switch (resData.code) {
          case 100005: // session invalid
          case 102003: // the user does not exist
            utils.clearLocalStorage(['rememberMe', 'applicationLocalStorage'])
            window.location.href = '/auth/login'
            return Promise.reject(error)
          case 103007: // MFA validation
            return Promise.reject(error)
          case 110077: // forgot email error
          case 110016: // User can not use previous 8 passwords
          case 110002: // current password invalid
          case 144058: // email exist error for adding borrower
          case 104112: // collateral account is pending
          case 115018:
          case 149413: // wet sign finish error
          case 170999:
            return Promise.reject(resData)
          case 100001: // for maintenance.html
            window.location.href = '/maintenance'
            break
          // default:
          //   // other error with alert
          //   systemError(resData)
          //   return Promise.reject(error)
        }

        // response status handling
        switch (error.response.status) {
          case 400:
            if (resData.code) {
              const resDataStr = resData.code + ''
              if (resDataStr.length === 6 && resDataStr.startsWith('11')) {
                if (resData.code === 110602) {
                  store.commit('setLogoutBecauseOfSingleDeviceRule', true)
                  deviceLogoutError({ message: resData.message })
                }
                if (resData.code === 110109 || resData.code === 110110) {
                  userError(resData)
                }
                return Promise.reject(error)
              }
            }
            switch (resData.code) {
              case 110601:
              case 144163:
              case 230927:
              case 120201:
              case 120202:
              case 120203:
              case 120204:
              case 120206:
              case 120207:
              case 120602:
              case 230100:
                return Promise.reject(resData)
              default:
                userError(resData)
            }
            break
          case 401:
            switch (resData.code) {
              case 114164: // mfa auth over time
              case 114316: // mfa auth over time
                globalThis.vm.$store.commit('setShowMFA', true)
                return Promise.reject(error)
              case 103013:
              case 136017: // TDAI server login invalid
              case 103001: // security answers are incorrect
              case 110401: // users were disabled or locked
              case 110402: // users were disabled or locked
              case 110107: // users were disabled or locked
              case 110116: // security answers are incorrect
              case 110408: // security answers are incorrect
              case 110101: // security answers are incorrect
              case 110102: // security answers are incorrect
              case 110103: // security answers are incorrect
              case 110104: // security answers are incorrect
              case 110105: // security answers are incorrect
              case 110106: // security answers are incorrect
              case 110109: // security answers are incorrect
              case 110110: // security answers are incorrect
              case 110114: // security answers are incorrect
              case 110117: // security answers are incorrect
                return Promise.reject(error)
              case 110006: // link invalid
                utils.clearLocalStorage([
                  'rememberMe',
                  'applicationLocalStorage',
                ])
                router.push({ path: '/link-expired' })
                return Promise.reject(error)
              default:
                utils.clearLocalStorage([
                  'rememberMe',
                  'applicationLocalStorage',
                ])
                return navigationToLoginOrLogout(error, true)
            }

          case 403:
            switch (resData.code) {
              case 159107: // not permission, others detail view
                router.push({ name: 'not-permission' })
                break
              case 116014: // CSRF refresh for valid
                location.reload()
                break

              case 110401: // users were disabled or locked
              case 110402:
              case 103012: // users were disabled or locked
              case 103013:
                window.location.href = '/auth/login'
                break

              case 120103: // old password is incorrect
              case 110025: // old password is incorrect
                return Promise.reject(resData)

              case 103010: // security questions
                return Promise.reject(error)

              default:
                return systemError(resData)
            }
            break

          case 404:
            if (error.config.method === 'head') {
              return Promise.reject(error.response.data)
            }
            systemError({ message: 'Request does not exist' })
            return Promise.reject(error)

          case 500:
            systemError(resData)
            break
        }
      } else {
        switch (error.response.status) {
          case 403:
            process.env.NODE_ENV === 'production' &&
              router.push({ name: 'not-permission' })
            break
          case 401:
            utils.clearLocalStorage(['rememberMe', 'applicationLocalStorage'])
            return navigationToLoginOrLogout(error)
          default:
            error.message = `Connection error ${error.response.status}`
        }
      }

      // If no match is found
      return Promise.reject(error)
    } else {
      const canceledErrorCode = 'ERR_CANCELED'
      if (error.code !== canceledErrorCode) {
        systemError({ message: 'Connection to server failed' })
        console.error(error)
      } else {
        return Promise.reject(error)
      }
    }
  } catch (err) {
    console.error(err)
  }
}
